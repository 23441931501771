var autocompleteMap = '';
$(function() {

    if ($('.store_locator.homepage').length > 0) {

        var searchElem = $('.store_locator.homepage .autocomplete_addr')[0];
        var countryFilter = $(searchElem).data('country');
        var option = {
            types: ['geocode']
        };
        if (countryFilter !== undefined && countryFilter !== '') {
            option.componentRestrictions = { country: countryFilter };
        }
    }
});
